import React from 'react';
import { Paper, Flex, Divider, Text, Stack, Group, LoadingOverlay, Box } from '@mantine/core';
import BingoCard from './BingoCard';
import QrCode from './QrCode';
import Shapes from './shapes';

function GamePagePreview(props) {

  const {
    scale,
    tracks,
    gameOptions,
    style,
    isLoading,
  } = props;

  const GAP = 28;
  const CARD_SCALE = 0.81;

  const getContent = () => {
    if (gameOptions) {
      const {
        title,
        qrCode,
        footer,
        textOptions,
        themeColor,
      } = gameOptions;
  
      const flexStyle = { flex: 1 }
      const headerStyle = { 
        fontFamily: textOptions.headerFont,
        fontWeight: textOptions.headerBold ? 700 : 400,
        fontSize: textOptions.headerSize,
        color: themeColor,
        margin: 0,
        lineHeight: '120%',
        ...flexStyle
      };
  
      const shapeStyle = { 
        width: 40,
        height: 40,
      }
  
      const featuredImageStyle = {
        height: 68,
        width: 'auto',
        maxWidth: 344,
        objectFit: 'contain',
        objectPosition: 'right'
      }

      return (<Box pos='relative' h={812 * scale} >
        <LoadingOverlay visible={isLoading} overlayBlur={2} />
        <Paper
          shadow='md'
          p={40}
          w={1056}
          h={812}
          style={{ 
            ...style,
            transform: `scale(${scale})`,
            transformOrigin: 'top left',
          }}
        >
          { tracks[0]?.length > 1 && <>
            <Flex gap={GAP} mb='xl' align='center'>
              <Text style={headerStyle}>
                {title}
              </Text>

              <Flex gap='none' align='flex-end' h={32} style={flexStyle}>
                <Text size={14 * CARD_SCALE} color='gray.7' mr='md' style={{ alignSelf: 'center' }}>
                  Name: 
                </Text>
                <Divider w='100%' color='gray.3' variant='dashed' />
              </Flex>

            </Flex>

            <Flex gap={GAP} mb='xl'>
              <BingoCard tracks={tracks[0]} gameOptions={gameOptions} style={flexStyle} scale={0.81} cardNumber={1} />
              <BingoCard tracks={tracks[1]} gameOptions={gameOptions} style={flexStyle} scale={0.81} cardNumber={2} />
            </Flex>

            <Flex gap={GAP} justify='space-between'>
              <Group w={360} spacing='md'>
                {footer.showShapes && Shapes.map((shape) => <img key={shape.name} src={shape.src} style={shapeStyle} alt={shape.name} />) }
              </Group>

              <Group spacing='lg'>
                <Stack spacing={8 * CARD_SCALE}>
                  { footer.showFeaturedImage && <img alt='featured' src={footer.featuredImageUrl} style={featuredImageStyle} /> }
                  <Text
                    size={14 * CARD_SCALE}
                    color='gray.9'
                    w={featuredImageStyle.maxWidth}
                    maw={featuredImageStyle.maxWidth}
                    align={footer.messageAlign}
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    {footer.message}
                  </Text>
                </Stack>
                { qrCode.showQrCode && <QrCode value={qrCode.url} size={96} /> }
              </Group>

            </Flex>
          </>}
        </Paper>
        </Box>
      )
    }
    return null;
  }

  return getContent();
  }

export default GamePagePreview;