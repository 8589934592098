import {
  HiOutlineAcademicCap,
  HiOutlineBeaker,
  HiOutlineBolt,
  HiOutlineCake,
  HiOutlineCheckCircle,
  HiOutlineFaceSmile,
  HiOutlineFire,
  HiOutlineGift,
  HiOutlineHandThumbUp,
  HiOutlineHeart,
  HiOutlineMusicalNote,
  HiOutlinePuzzlePiece,
  HiOutlineRocketLaunch,
  HiOutlineSparkles,
  HiOutlineStar,
  HiOutlineTrophy,
  HiOutlineMoon,
} from 'react-icons/hi2';

import ShamrockIcon from './ShamrockIcon';
import SkullIcon from './SkullIcon';

const FreeSpaceIcons = [
  {
    name: 'Skull',
    label: 'SkullIcon',
    icon: SkullIcon
  },
  {
    name: 'Shamrock',
    label: 'ShamrockIcon',
    icon: ShamrockIcon
  },
  {
    name: 'Star',
    label: 'HiOutlineStar',
    icon: HiOutlineStar
  },
  {
    name: 'Sparkles',
    label: 'HiOutlineSparkles',
    icon: HiOutlineSparkles
  },
  {
    name: 'Trophy',
    label: 'HiOutlineTrophy',
    icon: HiOutlineTrophy
  },
  {
    name: 'Music note',
    label: 'HiOutlineMusicalNote',
    icon: HiOutlineMusicalNote
  },
  {
    name: 'Fire',
    label: 'HiOutlineFire',
    icon: HiOutlineFire
  },
  {
    name: 'Bolt',
    label: 'HiOutlineBolt',
    icon: HiOutlineBolt
  },
  {
    name: 'Gift',
    label: 'HiOutlineGift',
    icon: HiOutlineGift
  },
  {
    name: 'Heart',
    label: 'HiOutlineHeart',
    icon: HiOutlineHeart
  },
  {
    name: 'Smile',
    label: 'HiOutlineFaceSmile',
    icon: HiOutlineFaceSmile
  },
  {
    name: 'Graduate cap',
    label: 'HiOutlineAcademicCap',
    icon: HiOutlineAcademicCap
  },
  {
    name: 'Beaker',
    label: 'HiOutlineBeaker',
    icon: HiOutlineBeaker
  },
  {
    name: 'Birthday cake',
    label: 'HiOutlineCake',
    icon: HiOutlineCake
  },
  {
    name: 'Check mark',
    label: 'HiOutlineCheckCircle',
    icon: HiOutlineCheckCircle
  },
  {
    name: 'Thumbs up',
    label: 'HiOutlineHandThumbUp',
    icon: HiOutlineHandThumbUp
  }, 
  {
    name: 'Puzzle piece',
    label: 'HiOutlinePuzzlePiece',
    icon: HiOutlinePuzzlePiece
  },
  {
    name: 'Rocket',
    label: 'HiOutlineRocketLaunch',
    icon: HiOutlineRocketLaunch
  },
  {
    name: 'Moon',
    label: 'HiOutlineMoon',
    icon: HiOutlineMoon
  }
];

export default FreeSpaceIcons;
